import { Box } from '@mui/material';
import { StyledTextareaAutosize } from 'components/StyledTextareaAutosize';
import { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';

type Props = ComponentProps<typeof StyledTextareaAutosize> & {
  actions: ReactNode;
  footer?: ReactNode;
  textAreaAdornment?: ReactNode;
};

export const ExtendedPromptInput = ({
  actions,
  footer,
  textAreaAdornment,
  ...textAreaProps
}: Props) => {
  return (
    <Box sx={{ flex: 1, position: 'relative', width: '100%' }}>
      <ExtendedTextAreaAutosize maxRows={15} {...textAreaProps} />
      {textAreaAdornment}
      <ActionsBox>{actions}</ActionsBox>
      {footer}
    </Box>
  );
};

const ActionsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: ${({ theme }) => theme.spacings.two};
  left: ${({ theme }) => theme.spacings.one};
  align-items: center;
  background: white;
  margin-left: 2px;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.two};
  width: calc(100% - 24px);
`;

const ExtendedTextAreaAutosize = styled(StyledTextareaAutosize)`
  padding: 16.5px 36px 51px 14px;
`;
