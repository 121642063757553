import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = {
  id?: string;

  onClick: () => void;
};

const CreateProjectButton = ({ id, onClick }: Props) => (
  <Root id={id} role="button" onClick={onClick} size="large">
    <FlexContainer direction="row" justifyContent="center" alignItems="center" gap="small">
      <AddIcon />
      <FormattedMessage id="common.new_document" />
    </FlexContainer>
  </Root>
);

const Root = styled(Button)`
  border-radius: ${({ theme }) => theme.borderRadius.one};
  box-shadow: ${({ theme }) => theme.shadow.elevation2};
  transition: ${({ theme }) =>
    theme.transition.create(
      theme.transition.default,
      'background-color',
      'color',
      'box-shadow',
      'border-color',
      'min-width'
    )};
  width: fit-content;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.card};
    background-color: ${({ theme }) => theme.colors.primaryColorDark};
  }

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: ${({ theme }) => `${theme.spacings.two} ${theme.spacings.four}`};
  background: ${({ theme }) => theme.colors.primaryColorMain};
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const AddIcon = styled(AddCircleIcon)`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

export default CreateProjectButton;
