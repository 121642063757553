import { ArticleRounded, ThumbUpAltRounded, WallpaperRounded } from '@mui/icons-material';
import { Avatar, Typography } from '@mui/material';
import CrownIcon from 'assets/icons/crown.svg';
import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import { CreateChatForm } from 'features/aiWriter/AiWriterProjectOverview/chatForm/CreateChatForm';
import { CreateWorkflowButton } from 'features/aiWriter/AiWriterProjectOverview/CreateWorkflowButton';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { getIsApiCustomer } from 'features/customer/store/selectors';
import { ApiDashboard } from 'features/homePage/ApiDashboard';
import { HomeFeatureCards } from 'features/homePage/HomeFeatureCards';
import { HomeRecentFiles } from 'features/homePage/HomeRecentFiles';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShouldShowOnboardingChecklist } from 'features/onboardingJourney/checklist/ChecklistButton';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount, useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import CreateProjectButton from 'features/projects/CreateProjectButton';
import { RecentProjectsGrid } from 'features/projects/ProjectOverview';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { newThemeConfig } from 'styles/new-theme/newThemeConfig';

const HomePage = (): ReactElement => {
  const navigate = useNavigate();
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const dispatch = useAppDispatch();
  const collapseSidebar = useSidebarStore(state => state.collapseSidebar);

  const shouldShowOnboardingChecklist = useShouldShowOnboardingChecklist();

  useEnableSidebarOnMount();

  useNavigateToOnboarding();

  const handleNewDocumentClick = async () => {
    await navigate(getRoutePath('aiWriter'));
    trackingWrapper.track('aiWriterOverviewNewDocumentStarted');

    navigate(getRoutePath('newDocument'));
  };

  const handleBlogPostBuilderClick = async () => {
    await navigate(getRoutePath('aiWriter'));
    trackingWrapper.track('aiWriterOverviewBlogPostBuilderStarted');

    collapseSidebar();

    navigate(getRoutePath('blogPostWorkflow'));
  };

  const handleSocialMediaBuilderClick = async () => {
    await navigate(getRoutePath('aiWriter'));
    trackingWrapper.track('aiWriterOverviewSocialMediaBuilderStarted');

    collapseSidebar();

    navigate(getRoutePath('socialPostWorkflow'));
    dispatch(setInspirationsCurrentStep(undefined));
  };

  const handleImageFlashClick = () => {
    navigate(getRoutePath('aiImages'));
  };

  if (isApiCustomer) {
    return <ApiDashboard />;
  }

  return (
    <Container size="lg">
      <ContentContainer gap="four">
        <CreateProjectButton
          id={gtmIds.aiWriter.createNewProject}
          onClick={handleNewDocumentClick}
        />
        <CreateChatForm />
        <TopContentContainer $twoColumns={shouldShowOnboardingChecklist}>
          <FlexContainer gap="two">
            <SectionHeadline>
              <FormattedMessage id="home.sections.tools.title" />
            </SectionHeadline>
            <WorkflowButtons>
              <CreateWorkflowButton
                title={<FormattedMessage id="aiWriter.project_overview.blog_post_builder" />}
                subtitle={
                  <FormattedMessage id="aiWriter.project_overview.blog_post_builder_description" />
                }
                gtmId={gtmIds.aiWriter.newBlogPostBuilder}
                onClick={handleBlogPostBuilderClick}
                icon={<ArticleIcon />}
              />
              <CreateWorkflowButton
                title={
                  <FormattedMessage id="aiWriter.project_overview.social_post_builder.title" />
                }
                subtitle={
                  <FormattedMessage id="aiWriter.project_overview.social_post_builder.subtitle" />
                }
                gtmId={gtmIds.aiWriter.newSocialPostBuilder}
                onClick={handleSocialMediaBuilderClick}
                icon={<ThumbUpIcon />}
              />
              <CreateWorkflowButton
                title={<FormattedMessage id="aiWriter.project_overview.ai_images" />}
                subtitle={<FormattedMessage id="aiWriter.project_overview.ai_images_description" />}
                gtmId={gtmIds.aiWriter.newAIImages}
                onClick={handleImageFlashClick}
                icon={<WallpaperIcon />}
              />
            </WorkflowButtons>
          </FlexContainer>
        </TopContentContainer>
        <FlexContainer gap="two">
          <SectionHeadline>
            <FormattedMessage id="home.sections.recentFiles.title" />
          </SectionHeadline>

          <RecentProjectsGrid>
            <HomeRecentFiles />
          </RecentProjectsGrid>
        </FlexContainer>
        <FlexContainer gap="two">
          <FlexContainer direction="row" gap="two">
            <SectionHeadline>
              <FormattedMessage id="home.sections.premiumTools.title" />
            </SectionHeadline>
            <StyledAvatar $bgColor={newThemeConfig.colors.primaryColorMain}>
              <img src={CrownIcon} />
            </StyledAvatar>
          </FlexContainer>
          <HomeFeatureCards />
        </FlexContainer>
      </ContentContainer>
    </Container>
  );
};

function SectionHeadline({ children }: { children: ReactNode }) {
  return <Typography variant="subtitle1">{children}</Typography>;
}

const ArticleIcon = styled(ArticleRounded)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.accentOrangeMain};
`;

const ThumbUpIcon = styled(ThumbUpAltRounded)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.accentOrangeMain};
`;

const WallpaperIcon = styled(WallpaperRounded)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.accentOrangeMain};
`;

const StyledAvatar = styled(Avatar)<{ $side?: string; $bgColor?: string }>`
  && {
    background-color: ${({ theme, $bgColor }) => $bgColor ?? theme.colors.accentOrangeMain};
    width: 24px;
    height: 24px;
  }
`;

const WorkflowButtons = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.three};
  grid-template-columns: repeat(4, 1fr);
`;

const ContentContainer = styled(FlexContainer)`
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};
`;

const TopContentContainer = styled.div<{ $twoColumns: boolean }>`
  display: grid;
  grid-template-columns: ${({ $twoColumns }) => `repeat(${$twoColumns ? 2 : 1}, 1fr)`};
  gap: ${({ theme }) => theme.spacings.medium};

  @media (max-width: 1280px) {
    grid-template-columns: repeat(1, 1fr);

    > :first-child {
      order: 2;
    }
  }
`;

export default HomePage;
