import { CircularProgress } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import dayjs from 'dayjs';
import { getProjectName } from 'features/aiWriter/AiWriterProjectOverview/getProjectName';
import { useRecentDocuments } from 'features/aiWriter/AiWriterProjectOverview/recent-documents/useRecentDocuments';
import { AiWriterProject } from 'features/aiWriter/store/types';
import { useGetAndLoadProjectById } from 'features/aiWriter/useGetAndLoadProjectById';
import { ProjectTile } from 'features/projects/ProjectTile';
import { useNavigate } from 'react-router';

export function HomeRecentFiles() {
  const { isLoading: areProjectsFetching, data, isError } = useRecentDocuments();
  const { fetchProject: fetchWriterProject } = useGetAndLoadProjectById();

  const navigate = useNavigate();

  const handleProjectClick = async (project: AiWriterProject) => {
    await navigate(getRoutePath('aiWriter'));
    fetchWriterProject({ id: project.id.toString() });
  };

  if (areProjectsFetching) {
    return (
      <FlexContainer justifyContent="center" alignItems="center">
        <CircularProgress />
      </FlexContainer>
    );
  }

  if (isError) {
    return null;
  }

  const projects = data;

  if (projects.length === 0) {
    return null;
  }

  return (
    <>
      {projects.map(project => (
        <ProjectTile
          key={project.id}
          project={project}
          onClick={() => handleProjectClick(project)}
          date={dayjs(project.updatedAt).format('DD.MM.YYYY')}
          tags={project.tags}
          embeddingModelId={project.embeddingModelId}
          title={getProjectName(project.name, project.short_text ?? '')}
          contentPreview={project.short_text ?? undefined}
          isLoading={areProjectsFetching}
        />
      ))}
    </>
  );
}
