import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { getRoutePath } from 'config/routes';
import dayjs from 'dayjs';
import { getProjectName } from 'features/aiWriter/AiWriterProjectOverview/getProjectName';
import { ProjectsMenu } from 'features/aiWriter/AiWriterProjectOverview/ProjectsMenu';
import { useRenameProjectModal } from 'features/aiWriter/AiWriterProjectOverview/RenameProjectModal';
import { useCopyDocument } from 'features/aiWriter/AiWriterProjectOverview/useCopyDocument';
import { useDeleteAiWriterProjects } from 'features/aiWriter/AiWriterProjectOverview/useDeleteAiWriterProjects';
import { loadTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/loadTabThunk';
import { AiWriterProject, AiWriterProjectWithShortText } from 'features/aiWriter/store/types';
import { useGetProjectById } from 'features/aiWriter/useGetProjectById';
import { ProjectTile } from 'features/projects/ProjectTile';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { invalidateProjectsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjects';
import { httpUpdateProject } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpUpdateProject';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch } from 'store/hooks';

export function AiWriterProjectTile(props: {
  project: AiWriterProjectWithShortText;
  isFetching?: boolean;
  isRecentSection?: boolean;
}) {
  const dispatch = useAppDispatch();
  const fullProjectRef = useRef<AiWriterProject>();
  const { id, name, updatedAt, embeddingModelId, short_text, tags } = props.project;
  const { isRecentSection } = props;
  const { mutate: deleteProject, isLoading: isDeleteLoading } = useDeleteAiWriterProjects();

  const [isClickPrevented, setIsClickPrevented] = useState(false);

  const { mutate: updateProject, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (payload: { id: string; project: AiWriterProject }) =>
      httpUpdateProject.callEndpoint(payload.id, payload.project),
    onSuccess: () => invalidateProjectsQuery()
  });

  const { isLoading: isFetchingLoading, fetchProject } = useGetProjectById();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOptionsOpen = Boolean(anchorEl);

  const handleClickOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };

  const showRenameProjectModal = useRenameProjectModal();

  const { copyDocument } = useCopyDocument();

  const isLoading = props.isFetching || isDeleteLoading || isUpdateLoading || isFetchingLoading;

  const fetchFullProjectData = async () => {
    if (fullProjectRef.current) {
      return fullProjectRef.current;
    }

    return fetchProject(
      { id },
      {
        onSuccess: data => {
          fullProjectRef.current = data;
        }
      }
    );
  };

  const handleRenameClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();

    showRenameProjectModal({
      oldName: name,
      onUpdate: async (name: string) => {
        const fullProject = await fetchFullProjectData();

        updateProject({
          id,
          project: {
            ...fullProject,
            name
          }
        });
      }
    });
  };

  const handleCopyDocument = async (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    const project = await fetchFullProjectData();

    copyDocument(project);
  };

  const menu = !isRecentSection && (
    <>
      <IconButton
        size="small"
        onClick={e => {
          e.stopPropagation();
          handleClickOption(e);
        }}
        disabled={isLoading}
      >
        <MoreVertIcon />
      </IconButton>

      <ProjectsMenu
        project={props.project}
        anchorEl={anchorEl}
        isOptionsOpen={isOptionsOpen}
        handleCloseOption={handleCloseOption}
        renameClick={handleRenameClick}
        copyDocument={handleCopyDocument}
        deleteProject={deleteProject}
      />
    </>
  );

  const navigate = useNavigate();
  const location = useLocation();

  const handleTileClick = async () => {
    // for some reason, we need to prevent executing the click when we open the tags menu
    // we use state variable for it and push it to the child component where the tags menu is opened and closed
    if (isClickPrevented) {
      return;
    }

    if (!isOptionsOpen && !isLoading) {
      fetchProject(
        { id },
        {
          onSuccess: async data => {
            dispatch(loadTabThunk(data));
            if (location.pathname !== getRoutePath('aiWriter')) {
              await navigate(getRoutePath('aiWriter'));
            }
          }
        }
      );
    }
  };

  return (
    <ProjectTile
      key={id}
      embeddingModelId={embeddingModelId}
      title={getProjectName(name, short_text ?? '')}
      date={dayjs(updatedAt).format('DD.MM.YYYY')}
      contentPreview={short_text || ''}
      onClick={handleTileClick}
      menu={menu}
      isLoading={isLoading}
      tags={tags}
      onShouldClickBePrevented={setIsClickPrevented}
      project={props.project}
      gtmId={gtmIds.aiWriter.loadProject}
    />
  );
}
