import { Button, Typography } from '@mui/material';
import AutocompleteChipsInput from 'components/AutocompleteInput/AutocompleteChipsInput';
import { useModal } from 'components/modals';
import { getCurrentTabModelData, getIsTabInitialized } from 'features/explorer/store/selectors';
import { initializeTab } from 'features/explorer/store/slice';
import { ConceptFlashGoal, GoalValues, ModelValues } from 'features/explorer/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type Props = {
  seedWords: string[];
  onChange: (newSeedWords: string[]) => void;
  submitForm: () => void;
  onSetModel: (values: ModelValues) => void;
  onSetGoal: (values: GoalValues) => void;
};

export const TabInitialization = ({
  seedWords,
  onChange,
  submitForm,
  onSetModel,
  onSetGoal
}: Props) => {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const isInitialized = useAppSelector(getIsTabInitialized);
  const model = useAppSelector(getCurrentTabModelData);
  const {
    values: { wordAttributes, seoParams, manualDimensions, dimensions }
  } = useFormikContext<ConceptFlashGoal>();

  return (
    <InitialFormContainer>
      <div className="mb-4 text-center">
        {model ? (
          <Typography variant="h4">
            <FormattedMessage id="exploring.form.title" />
          </Typography>
        ) : (
          <FormattedMessage id="exploring.form.next_step" tagName="h3" />
        )}
      </div>

      {model && (
        <>
          <AutocompleteChipsInput
            onChange={newSeedWords => {
              if (!isInitialized) {
                dispatch(initializeTab(newSeedWords[0]));
              }

              onChange(newSeedWords);
            }}
            model={model?.id}
            initialValue={seedWords}
            textFieldProps={{
              variant: 'outlined',
              fullWidth: true,
              label: <FormattedMessage id="common.autocomplete.tags_input.placeholder" />,
              placeholder: '',
              onKeyDown: event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  submitForm();
                }
              }
            }}
            sx={{
              width: '100%',
              maxWidth: '40rem'
            }}
          />

          <ActionsContainer>
            <Button
              color="primary"
              onClick={() =>
                showModal({
                  modalType: 'EXPLORING_MODEL_MODAL',
                  modalProps: {
                    size: 900,
                    onSubmit: onSetModel
                  }
                })
              }
            >
              <FormattedMessage id="common.settings" />
            </Button>
            <Button
              color="primary"
              onClick={() =>
                showModal({
                  modalType: 'EXPLORING_GOAL_MODAL',
                  modalProps: {
                    size: 900,
                    onSubmit: onSetGoal,
                    initialValues: { wordAttributes, seoParams, manualDimensions, dimensions }
                  }
                })
              }
            >
              <FormattedMessage id="goal_score.goals" />
            </Button>
          </ActionsContainer>
        </>
      )}
    </InitialFormContainer>
  );
};

const InitialFormContainer = styled.div.attrs(() => ({ className: 'container' }))`
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ActionsContainer = styled.div`
  width: 40rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
