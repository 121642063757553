import { Container } from 'components/Container';
import { NewProjectsSection } from 'features/aiWriter/AiWriterProjectOverview/NewProjectsSection';
import { TagsBanner } from 'features/aiWriter/AiWriterProjectOverview/TagsBanner';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import styled from 'styled-components';

export function DocumentsPage() {
  useEnableSidebarOnMount();
  useNavigateToOnboarding();
  return (
    <Root>
      <TagsBanner />
      <NewProjectsSection enableProjectCreation />
    </Root>
  );
}

const Root = styled(Container).attrs({ size: 'lg' })`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.four};
`;
