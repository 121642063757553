import aiWriterIcon from 'assets/icons/ai-writer-sidebar/icon-ai-writer-primary.svg';
import aiWriterIconSelected from 'assets/icons/ai-writer-sidebar/icon-ai-writer-primary-selected.svg';
import iconChat from 'assets/icons/ai-writer-sidebar/icon-chat-flash.svg';
import iconChatSelected from 'assets/icons/ai-writer-sidebar/icon-chat-flash-selected.svg';
import flashScoreIcon from 'assets/icons/ai-writer-sidebar/icon-flash-score.svg';
import flashScoreIconSelected from 'assets/icons/ai-writer-sidebar/icon-flash-score-selected.svg';
import imageSearchIcon from 'assets/icons/ai-writer-sidebar/icon-unsplash.svg';
import imageSearchIconSelected from 'assets/icons/ai-writer-sidebar/icon-unsplash-selected.svg';
import SelectableIconButton from 'components/buttons/SelectableIconButton';
import FlexContainer from 'components/FlexContainer';
import LocalStorageKey from 'config/localStorageKey';
import { useCalculateInspirationsStep } from 'features/aiWriter/AiWriterSidebar/hooks/useCalculateInspirationsStep';
import { DRAWER_BUTTONS_WIDTH } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarDrawer';
import { mkInspirationStepTooltip } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/mkInspirationStepTooltip';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { getInspirationsCurrentStep } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import useTr from 'utils/hooks/useTr';

const sidebarButtonsContainerHeight = 200;

type Props = {
  isDisabled?: boolean;
  onStepChange: (step: AiWriterSidebarStep) => void;
};

export const AiWriterSidebarButtons = ({ isDisabled = false, onStepChange }: Props) => {
  const dispatch = useAppDispatch();
  const translate = useTr();
  const [, setChatClicked] = useLocalStorage(LocalStorageKey.AiWriterSidebarChatClicked, 0);

  const inspirationsCurrentStep = useAppSelector(getInspirationsCurrentStep);

  const calculateInspirationsStep = useCalculateInspirationsStep();

  const setInspirationsPanel = (step: AiWriterSidebarStep | undefined) => {
    if (step === inspirationsCurrentStep) {
      dispatch(setInspirationsCurrentStep(undefined));
      return;
    }

    const calculatedStep = calculateInspirationsStep(step);
    dispatch(setInspirationsCurrentStep(calculatedStep));
  };

  const handleChatClick = () => {
    setChatClicked(prevValue => Number(prevValue) + 1);
    handleChangeStep(AiWriterSidebarStep.chat);
  };

  const handleChangeStep = (step: AiWriterSidebarStep) => {
    onStepChange(step);
    setInspirationsPanel(step);
  };

  return (
    <ButtonsBar $height={sidebarButtonsContainerHeight}>
      <FlexContainer gap="two">
        <SelectableIconButton
          id={gtmIds.aiWriter.openTextInspirationsPanel}
          isSelected={inspirationsCurrentStep === AiWriterSidebarStep.aiWriter}
          icon={aiWriterIcon}
          tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.aiWriter))}
          selectedIcon={aiWriterIconSelected}
          onClick={() => handleChangeStep(AiWriterSidebarStep.aiWriter)}
          isDisabled={isDisabled}
        />
        <SelectableIconButton
          gtmId={gtmIds.aiWriter.chat.openTab}
          isSelected={inspirationsCurrentStep === AiWriterSidebarStep.chat}
          icon={iconChat}
          tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.chat))}
          selectedIcon={iconChatSelected}
          onClick={handleChatClick}
          isDisabled={isDisabled}
        />
        <SelectableIconButton
          id={gtmIds.aiWriter.images.openTab}
          isSelected={inspirationsCurrentStep === AiWriterSidebarStep.images}
          icon={imageSearchIcon}
          tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.images))}
          selectedIcon={imageSearchIconSelected}
          onClick={() => handleChangeStep(AiWriterSidebarStep.images)}
          isDisabled={isDisabled}
        />
        <SelectableIconButton
          gtmId={gtmIds.aiWriter.flashScore.openTab} //TODO: create new tracking
          isSelected={inspirationsCurrentStep === AiWriterSidebarStep.performance}
          icon={flashScoreIcon}
          selectedIcon={flashScoreIconSelected}
          tooltip={translate(mkInspirationStepTooltip(AiWriterSidebarStep.performance))}
          onClick={() => handleChangeStep(AiWriterSidebarStep.performance)}
          isDisabled={isDisabled}
          isNewFeature={true}
        />
      </FlexContainer>
    </ButtonsBar>
  );
};

const ButtonsBar = styled.div<{ $height: number }>`
  align-self: flex-start;

  position: relative;
  right: -1px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  flex: 0 1 ${DRAWER_BUTTONS_WIDTH}px;
  width: ${DRAWER_BUTTONS_WIDTH}px;
  height: ${({ $height }) => $height}px;
  // Push it down to make it look like it's connected to the sidebar views
  margin-top: 66px;
  padding: 0.5rem 0;

  border-radius: ${({ theme }) => theme.borderRadius.medium} 0 0
    ${({ theme }) => theme.borderRadius.medium};
  border-left: 0.5px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  border-top: 0.5px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  border-right: 1px solid #fff;
`;
