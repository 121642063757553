import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ColoredTypography } from 'components/ColoredTypography';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { CustomerAvatar } from 'features/customer/CustomerAvatar';
import {
  getCustomerRole,
  getIsApiCustomer,
  getUserImage,
  getUserName
} from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import { BillingItem } from 'features/pageSidebar/sidebarItems/actions/account/BillingItem';
import { DarkModeToggleItem } from 'features/pageSidebar/sidebarItems/actions/account/DarkModeToggleItem';
import { DashboardItem } from 'features/pageSidebar/sidebarItems/actions/account/DashboardItem';
import DimensionsItem from 'features/pageSidebar/sidebarItems/actions/account/DimensionsItem';
import ProfileItem from 'features/pageSidebar/sidebarItems/actions/account/ProfileItem';
import SignOutItem from 'features/pageSidebar/sidebarItems/actions/account/SignOutItem';
import { UsersItem } from 'features/pageSidebar/sidebarItems/actions/account/UsersItem';
import { LanguageButton } from 'features/pageSidebar/sidebarItems/actions/LanguageButton';
import { SidebarItem } from 'features/pageSidebar/sidebarItems/SidebarItem';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import React, { MouseEvent } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

export const SidebarItemAccount = (): React.ReactElement => {
  const { anchorElement, isOpen, open, close } = useDropdown();
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const userRole = useAppSelector(getCustomerRole);
  const name = useAppSelector(getUserName);
  const image = useAppSelector(getUserImage);
  const collapseSidebar = useSidebarStore(state => state.collapseSidebar);

  const handleClose = () => {
    close();
    collapseSidebar();
  };

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    open(event);
  };

  return (
    <MenuDropdown
      trigger={
        <SidebarItem
          gtmId={gtmIds.sidebar.accountMenu}
          icon={<CustomerAvatar name={name} image={image ?? undefined} />}
          onClick={handleOpen}
          tooltip="layout.sidebar.actions.profile.menu_trigger"
        >
          <LabelContainer>
            <ColoredTypography variant="subtitle2" color="blackMediumEmphasis">
              {name}
            </ColoredTypography>
            {isOpen ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          </LabelContainer>
        </SidebarItem>
      }
      isOpen={isOpen}
      anchorElement={anchorElement}
      close={close}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      {!isApiCustomer && <DimensionsItem onClose={handleClose} />}
      {!isApiCustomer && <ProfileItem onClose={handleClose} />}
      <DarkModeToggleItem />
      <DashboardItem onClose={handleClose} />
      <LanguageButton onClose={handleClose} />
      {userRole === CustomerRole.OWNER && <BillingItem onClose={handleClose} />}
      <UsersItem onClose={handleClose} />
      <SignOutItem onClose={handleClose} />
    </MenuDropdown>
  );
};

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
