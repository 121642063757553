import { Avatar, Button, Checkbox, Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { Card } from 'components/profile/components/common';
import Toast from 'components/toasts/Toast';
import LocalStorageKey from 'config/localStorageKey';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { Loader } from 'features/churnModal/loader';
import { getIsApiCustomer } from 'features/customer/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import {
  Language,
  ProfileInformationLanguageField
} from 'features/profilePage/ProfileInformationLanguageField';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type Props = {
  username: string;
  email: string;
  timezone: string;
  groupName: string;
  avatarImage?: string;
};

export const ProfileInformationCard = ({
  username,
  email,
  timezone,
  groupName,
  avatarImage
}: Props) => {
  const { showModal } = useModal();
  const { data: preferences, isLoading: isLoadingCustomerPreferences } = useCustomerPreferences();
  const { mutateAsync: updatePreferences } = useUpdateCustomerPreferences();

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const { currentModelCountry, currentModelLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const languageToolEnabled = preferences?.languageToolEnabled ?? false;

  const [checkboxChecked, setCheckboxChecked] = useState(languageToolEnabled);

  useEffect(() => {
    if (preferences && !isLoadingCustomerPreferences) {
      setCheckboxChecked(preferences.languageToolEnabled);
    }
  }, [preferences, isLoadingCustomerPreferences]);

  const selectedLanguageModelId = models.find(
    model =>
      model.language === preferences?.preferredLanguage &&
      model.country === preferences.preferredLanguageCountry
  )?.id;
  const defaultLanguageModelId = models.find(
    model => model.language === currentModelLanguage && model.country === currentModelCountry
  )?.id;

  const initialValues = {
    language: selectedLanguageModelId || defaultLanguageModelId,
    country: currentModelCountry
  };

  useEnableSidebarOnMount();

  const handleCheckbox = () => {
    setCheckboxChecked(!checkboxChecked);
    updatePreferences({ languageToolEnabled: !checkboxChecked }).then(() => {
      Toast.success('profile.success');
    });
  };

  const handleSubmit = (values: Language) => {
    const embeddingModel = models.find(model => model.id === values.language);
    const updatedValues = {
      language: embeddingModel?.language,
      country: embeddingModel?.country
    };
    const embeddingModelId = embeddingModel?.id;
    assertNonNullable(embeddingModelId, 'Missing model!');

    updatePreferences({
      preferredLanguage: updatedValues.language,
      preferredLanguageCountry: updatedValues.country
    }).then(() => {
      Toast.success('profile.success');
    });
    localStorage.setItem(LocalStorageKey.AiWriterModel, String(embeddingModelId));
  };

  return (
    <Root variant="outlined">
      <Header>
        <FlexContainer gap="three" direction="row" alignItems="center">
          <StyledAvatar src={avatarImage ?? undefined}>{username[0]}</StyledAvatar>
          <FlexContainer gap="two">
            <Typography variant="h5">{username}</Typography>
            <ColoredTypography variant="body2" color="textSecondary">
              {email}
            </ColoredTypography>
          </FlexContainer>
        </FlexContainer>

        {!isApiCustomer && (
          <EditButton color="primary" variant="outlined" onClick={() => showModal('EDIT_PROFILE')}>
            <FormattedMessage id="profile.edit_profile" />
          </EditButton>
        )}
      </Header>
      {isLoadingCustomerPreferences ? (
        <Loader />
      ) : (
        <>
          <FlexContainer gap="two" direction="row">
            <Typography variant="subtitle2">
              <FormattedMessage id="profile.column.time_zone" />:
            </Typography>
            <Typography variant="subtitle2">{timezone}</Typography>
          </FlexContainer>
          <FlexContainer gap="two" direction="row">
            <Typography variant="subtitle2">
              <FormattedMessage id="profile.column.team" />:
            </Typography>
            <Typography variant="subtitle2">{groupName}</Typography>
          </FlexContainer>
          <LanguageToolContainer>
            <Typography variant="subtitle2">
              <FormattedMessage id="profile.settings.language_tool.headline" />
            </Typography>
            <CheckboxContainer>
              <StyledCheckbox onChange={handleCheckbox} checked={checkboxChecked} />
              <Typography variant="body1">
                <FormattedMessage id="profile.settings.language_tool.checkbox_label" />
              </Typography>
            </CheckboxContainer>
          </LanguageToolContainer>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
              <ProfileInformationLanguageField
                name="language"
                models={models}
                onSubmit={handleSubmit}
              />
            </Form>
          </Formik>
        </>
      )}
    </Root>
  );
};

const StyledAvatar = styled(Avatar)`
  && {
    width: 80px;
    height: 80px;
  }
`;

const EditButton = styled(Button)`
  && {
    max-height: 42px;
  }
`;

const Header = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
  direction: 'row'
})``;

const Root = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
`;

const StyledCheckbox = styled(Checkbox)`
  // The checkbox is not aligned with the text without this
  margin-top: -9px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.two};
`;

const LanguageToolContainer = styled(FlexContainer).attrs({ direction: 'row' })`
  gap: ${({ theme }) => theme.spacings.two};
`;
