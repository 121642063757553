import FlexContainer from 'components/FlexContainer';
import { getRoutePath, RestrictedRoute } from 'config/routes';
import { CreateWorkflowButton } from 'features/aiWriter/AiWriterProjectOverview/CreateWorkflowButton';
import { getRestrictions } from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';

const useIsRestricted = (slug: RestrictedRoute) => {
  const restrictions = useAppSelector(getRestrictions);

  return restrictions[slug] ?? false;
};

export const HomeFeatureCards = (): ReactElement => {
  return (
    <FlexContainer direction="row" gap="three">
      <TestingCard />
      <ExploringCard />
    </FlexContainer>
  );
};

function TestingCard() {
  const restricted = useIsRestricted('aiTester');
  const navigate = useNavigate();

  return (
    <CreateWorkflowButton
      title={<FormattedMessage id="product.create_and_compare" />}
      subtitle={<FormattedMessage id="product_desc.create_and_compare" />}
      gtmId={gtmIds.home.toolCard.testing}
      onClick={() => navigate(getRoutePath(restricted ? 'productInfoTesting' : 'aiTester'))}
      icon={false}
    />
  );
}

function ExploringCard() {
  const restricted = useIsRestricted('exploring');
  const navigate = useNavigate();

  return (
    <CreateWorkflowButton
      title={<FormattedMessage id="product.explorer" />}
      subtitle={<FormattedMessage id="product_desc.explorer" />}
      gtmId={gtmIds.home.toolCard.exploring}
      onClick={() => navigate(getRoutePath(restricted ? 'productInfoExploring' : 'exploring'))}
      icon={false}
    />
  );
}
